import { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { chakra, Fade, useMediaQuery } from '@chakra-ui/react'

const getVideoUrl = (isTablet: boolean, isDesktop: boolean) => {
  if (isDesktop) {
    return '/videos/background_desktop.mp4'
  }

  if (isTablet) {
    return '/videos/background_tablet_landscape.mp4'
  }

  return '/videos/background_mobile.mp4'
}

const BackgroundVideo = () => {
  const [isTablet, isDesktop] = useMediaQuery(['(min-width: 768px)', '(min-width: 992px)'])

  const videoRef = useRef<HTMLVideoElement>(null)
  const [videoUrl, setVideoUrl] = useState('')
  const [windowWidth, setWindowWidth] = useState<number | null>(null)
  const [isLoading, setLoading] = useState(true)

  const initializeVideo = useCallback(() => {
    const newVideoUrl = getVideoUrl(isTablet, isDesktop)
    if (newVideoUrl !== videoUrl) {
      setLoading(true)
      setVideoUrl(newVideoUrl)
      if (videoRef.current) {
        videoRef.current.load()
      }
    }
  }, [isTablet, isDesktop, videoUrl])

  const changeVideoUrl = useCallback(() => {
    if (window.innerWidth !== windowWidth) {
      initializeVideo()
      setWindowWidth(window.innerWidth)
    }
  }, [windowWidth, initializeVideo])

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)
    initializeVideo()
    window.addEventListener('resize', changeVideoUrl)

    return () => {
      window.removeEventListener('resize', changeVideoUrl)
    }
  }, [changeVideoUrl, initializeVideo])

  return (
    <Fade in={!isLoading}>
      <chakra.video
        ref={videoRef}
        playsInline
        muted
        autoPlay
        loop
        onPlay={() => setLoading(false)}
        position="absolute"
        top="0"
        left="0"
        zIndex="0"
        w="full"
      >
        <source src={videoUrl} type="video/mp4" />
      </chakra.video>
    </Fade>
  )
}

export default BackgroundVideo
