import { Container, Heading, Image, Stack } from '@chakra-ui/react'

import { useTranslation } from '~/lib/i18n'
import SocialBox, { SocialType } from './SocialBox'

const Socials = () => {
  const { t } = useTranslation('home')

  return (
    <>
      <Image
        visibility={{ base: 'hidden', md: 'visible' }}
        h={{ md: '500px', '2xl': '650px' }}
        src="/assets/reflection-sub.png"
        pos="absolute"
        right={{ md: -10, '2xl': 0 }}
        top={800}
        zIndex={-1}
      />
      <Container maxW="container.xl">
        <Heading
          textAlign="center"
          fontSize="40px"
          fontWeight={700}
          lineHeight="48px"
          mb={0}
          bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
          bgClip="text"
          pt={{ base: '90px', md: '240px' }}
          pb={12}
        >
          {t('socials.title')}
        </Heading>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify="center"
          bgImage="/assets/vector-socials.svg"
          backgroundSize="70%"
          backgroundPosition="center"
        >
          <SocialBox social={SocialType.X} />
          <SocialBox social={SocialType.DISCORD} />
        </Stack>
      </Container>
    </>
  )
}

export default Socials
