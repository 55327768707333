import { Box, Flex, GridItem, Heading, Image, Link as ChakraLink, Text, TextProps, VStack } from '@chakra-ui/react'
import Link from 'next/link'

import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'

interface Props {
  title: string
  description?: string
  span?: number
  imgSource: string
  inverted?: boolean
  url?: string
  isAudit?: boolean
}

const AuditDescription = () => {
  const { t } = useTranslation('home')

  return (
    <Flex flexDir={{ base: 'column', xl: 'row' }} gap={{ base: 0, xl: 1 }}>
      <Flex alignItems="baseline" gap={1} flexWrap="wrap" justifyContent="center">
        <Text color={theme.colors.text.secondary} textAlign="center">
          {t('cryptoInvestors.features.reliable.auditedBy')}
        </Text>
        <ChakraLink
          as={Link}
          target="_blank"
          href="https://medium.com/@Deepr.Finance/deepr-protocol-completes-successful-audit-by-hashex-70b10304031f"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <FeaturesItemText>{t('cryptoInvestors.features.reliable.hashex')}</FeaturesItemText>
        </ChakraLink>
      </Flex>
      <Flex alignItems="baseline" gap={1} flexWrap="wrap" justifyContent="center">
        <Text color={theme.colors.text.secondary} textAlign="center">
          {t('cryptoInvestors.features.reliable.connector')}
        </Text>
        <ChakraLink
          as={Link}
          target="_blank"
          href="https://github.com/sherlock-protocol/sherlock-reports/blob/main/audits/2024.07.11%20-%20Final%20-%20Deepr%20Audit%20Report.pdf"
          _hover={{
            textDecoration: 'none',
          }}
        >
          <FeaturesItemText>{t('cryptoInvestors.features.reliable.sherlock')}</FeaturesItemText>
        </ChakraLink>
      </Flex>
    </Flex>
  )
}

const FeaturesItemText = (props: TextProps) => {
  return (
    <Text
      transition="0.5s"
      _hover={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), linear-gradient(93.74deg, #6153CC 3.56%, #795EC1 97.35%)',
        bgClip: 'text',
      }}
      bgGradient="linear(to-r, #6153CC, #795EC1)"
      bgClip="text"
      textAlign="center"
      fontWeight={600}
      borderBottom="1px solid"
      style={{ borderImage: 'linear-gradient(90deg, #6153CC 50%, #795EC1 100%) 1' }}
      {...props}
    />
  )
}

const FeaturesItem = ({ title, description, span, imgSource, inverted, isAudit = false }: Props) => {
  return (
    <GridItem colSpan={span} h={{ base: '100%', md: '420px' }}>
      <Box borderRadius="3xl" border="1px solid #FFFFFF12" backdropFilter="blur(16px)" h="100%">
        <Box bgImage="/assets/noise-texture.png" h="full">
          <Box
            borderRadius="3xl"
            bgGradient="linear-gradient(118.15deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.02) 100%)"
            h="100%"
          >
            <VStack p={{ base: 7, md: 10 }} justify="space-between" h="100%">
              {!inverted && <Image src={imgSource} pb={{ base: 3, md: 6 }} />}
              <VStack>
                <Heading fontSize="2xl" textAlign="center">
                  {title}
                </Heading>
                {isAudit ? (
                  <AuditDescription />
                ) : (
                  <Text
                    color={theme.colors.text.secondary}
                    textAlign="center"
                    whiteSpace={{ base: 'pre-line', xl: 'initial' }}
                  >
                    {description}
                  </Text>
                )}
              </VStack>
              {inverted && <Image src={imgSource} pt={6} />}
            </VStack>
          </Box>
        </Box>
      </Box>
    </GridItem>
  )
}

export default FeaturesItem
