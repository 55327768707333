import { useEffect, useState } from 'react'
import { Box, Container, Flex, Heading, Image, Link as ChakraLink, Stack, Text } from '@chakra-ui/react'
import Link from 'next/link'

import { useTranslation } from '~/lib/i18n'
import { theme } from '~/styles/theme'
import BackgroundVideo from '../common/BackgroundVideo'
import { ButtonSize, ButtonVariant, CustomButton } from '../layout/CustomButton'

const MEDIUM_POST_URL =
  'https://medium.com/@Deepr.Finance/launching-deepr-finance-your-gateway-for-lending-and-borrowing-f8a5ef5888fa'

const Hero = () => {
  const { t } = useTranslation('home')
  const [showVideo, setShowVideo] = useState(false)

  // Fixes useLayoutEffect use in SSR.
  useEffect(() => {
    setShowVideo(true)
  }, [])

  return (
    <>
      {showVideo && <BackgroundVideo />}
      <Container height="100%" maxW="container.xl" zIndex="1" position="relative" pb={{ base: '64px', md: '0' }}>
        <Image
          h={{ base: '300px', md: '500px', '2xl': '650px' }}
          src="/assets/no-reflection-sub.png"
          pos="absolute"
          left={{ base: -20, md: -145, xl: '-15%', '2xl': '-22%' }}
        />
        <Flex
          minH={{ base: '120', md: '544', '2xl': '762' }}
          pt={{ base: 20, md: '0' }}
          flexDir="column"
          alignItems={{ base: 'center', md: 'center' }}
          justifyContent="center"
        >
          <Heading
            fontSize={{ base: '4xl', md: '5xl', '2xl': '7xl' }}
            maxW={{ base: '80%', md: '70%' }}
            textAlign="center"
            fontWeight={700}
            lineHeight={{ base: '48px', md: '72px', '2xl': '92px' }}
            mb={0}
            bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
            bgClip="text"
          >
            {t('hero.titleA')}
          </Heading>
          <Box maxW="580" mt={4}>
            <Text
              color={theme.colors.text.secondary}
              textAlign="center"
              maxW={{ base: '300px', md: '500px' }}
              whiteSpace={{ base: 'initial', md: 'pre-line' }}
            >
              {t('hero.description')}
            </Text>
          </Box>
          <Stack direction={{ base: 'column', md: 'row' }} mt={10} w={{ base: 'full', md: 'auto' }} spacing="4">
            <ChakraLink as={Link} href="/dashboard" _hover={{ textDecoration: 'none' }}>
              <CustomButton size={ButtonSize.LARGE} w={{ base: 'full', md: 'auto' }}>
                {t('hero.getStarted')}
              </CustomButton>
            </ChakraLink>
            <ChakraLink href={MEDIUM_POST_URL} _hover={{ textDecoration: 'none' }} target="_blank">
              <CustomButton size={ButtonSize.LARGE} variant={ButtonVariant.OUTLINE} w={{ base: 'full', md: 'auto' }}>
                {t('hero.learnMore')}
              </CustomButton>
            </ChakraLink>
          </Stack>
        </Flex>
      </Container>
    </>
  )
}

export default Hero
