import { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import { useTranslation } from '~/lib/i18n'
import AbsoluteFeaturesItem from './AbsoluteFeaturesItem'
import FeaturesItem from './FeaturesItem'

import 'swiper/css'
import 'swiper/css/pagination'

const FeaturesCarousel = () => {
  const { t } = useTranslation('home')
  const pagination = {
    clickable: true,
    dynamicBullets: true,
  }

  return (
    <Swiper
      pagination={pagination}
      modules={[Pagination]}
      spaceBetween={25}
      slidesPerView={1}
      loop
      centeredSlides
      grabCursor
    >
      <SwiperSlide style={{ height: 'auto' }}>
        <AbsoluteFeaturesItem
          title={t('cryptoInvestors.features.lendAndBorrow.title')}
          description={t('cryptoInvestors.features.lendAndBorrow.description')}
          imgSource="/assets/markets-preview-mobile.png"
          inverted
        />
      </SwiperSlide>
      <SwiperSlide style={{ height: 'auto' }}>
        <FeaturesItem title={t('cryptoInvestors.features.reliable.title')} imgSource="/assets/shield.svg" isAudit />
      </SwiperSlide>
      <SwiperSlide style={{ height: 'auto' }}>
        <AbsoluteFeaturesItem
          title={t('cryptoInvestors.features.streamlined.title')}
          description={t('cryptoInvestors.features.streamlined.description')}
          imgSource="/assets/lighthouse-preview-mobile.png"
        />
      </SwiperSlide>
      <SwiperSlide style={{ height: 'auto' }}>
        <FeaturesItem
          title={t('cryptoInvestors.features.fast.title')}
          description={t('cryptoInvestors.features.fast.description')}
          imgSource="/assets/fast.svg"
        />
      </SwiperSlide>
    </Swiper>
  )
}

export default FeaturesCarousel
