import { Flex, Heading, Image, Link as ChakraLink, useBreakpointValue, VStack } from '@chakra-ui/react'
import Link from 'next/link'

import { ButtonSize, CustomButton } from '~/components/layout/CustomButton'
import { useTranslation } from '~/lib/i18n'

const Join = () => {
  const { t } = useTranslation('home')
  const showLineBreakTitle = useBreakpointValue({ base: true, md: false })

  return (
    <>
      <Image
        visibility={{ base: 'visible', md: 'hidden' }}
        h="288px"
        src="/assets/reflection-sub.png"
        pos="absolute"
        right={-10}
        bottom={294}
        zIndex={-1}
      />
      <VStack pt={{ base: '140px', md: '240px', lg: '180px', xl: '140px', '2xl': '100px' }} pos="relative">
        <Flex
          direction="column"
          justify="center"
          align="center"
          position="absolute"
          top={{ base: '140px', md: '240px' }}
        >
          <Heading
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', '2xl': '7xl' }}
            textAlign="center"
            fontWeight={700}
            whiteSpace="pre-line"
            lineHeight={{ base: '48px', md: '72px' }}
            bgGradient="linear(to-b, #EBEBEB, #CCCCCC)"
            bgClip="text"
            mb={{ base: 6, md: 10 }}
          >
            {showLineBreakTitle ? t('join.titleWithLineBreak') : t('join.title')}
          </Heading>
          <ChakraLink as={Link} href="/dashboard" _hover={{ textDecoration: 'none' }}>
            <CustomButton size={ButtonSize.LARGE}>{t('join.launchApp')}</CustomButton>
          </ChakraLink>
        </Flex>
        <Image src="/assets/city.png" h={{ base: '337px', md: 'auto' }} objectFit="cover" />
      </VStack>
    </>
  )
}

export default Join
